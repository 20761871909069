<template>
  <CollapsibleSection :barText="'Compliance Status'"
                      :toggleHeaderColor=false
                      :bottomBorder=false
                      class='sl-mb-20px'>
    <form @submit.prevent='save'>
      <table v-if='statuses.length || addStatus' class='sl-simple-table-1 sl-border-b-2px sl-font-size-14px '>
        <thead>
          <tr class='sl-h-42px sl-border-t-0'>
            <th class='sl-text-align-left' style='width: 25%'>Policy Name</th>
            <th class='sl-text-align-left' style='width: 15%'>Coverage Status</th>
            <th class='sl-text-align-left' style='width: 15%'>Compliance Status</th>
            <th class='sl-text-align-left' style='width: 10%'>Year</th>
            <th class='sl-text-align-left' style='width: 35%'>
              <div class='sl-flex-align-items-center sl-flex-justify-content-space-btwn'>
                <span>Notes</span>
                <div class='sl-flex-only sl-flex-align-items-center'>
                  <span class='sl-blue-text sl-mr-10px'>{{ alertMessage }}</span>
                  <span class='sl-red-text sl-mr-10px'>{{ errorMessage }}</span>
                  <div v-if='showSpinningWheel' class='sl-spinning-wheel-20px sl-mr-10px' style='height: 20px'></div>
                  <div v-if='addStatus || editStatus' class=''>
                    <button type='submit' class='sl-simple-outline-btn sl-w-100px sl-mr-10px'
                            :class='{ "sl-simple-outline-btn-disabled": disableButtons }'>
                            Save
                    </button>
                    <button @click='cancel' class='sl-simple-outline-btn-warning sl-w-100px'
                            :class='{ "sl-simple-outline-btn-disabled": disableButtons }'>
                            Cancel
                    </button>
                  </div>
                  <button v-else @click='createNewStatus'
                          class='sl-simple-outline-btn sl-w-100px'
                          :class='{ "sl-simple-outline-btn-disabled": disableButtons }'>
                          Add Status
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='status in statuses' :key='status.id' class='sl-h-42px'>
            <td class='sl-text-align-left'>
              <select v-if='status.edit'
                      v-model='status.policy_name'
                      class='sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px sl-w-90per'
                      required>
                <option v-for='policy in activePolicies' :key='policy.id'>{{ policy }}</option>
              </select>
              <span v-else>{{ status.policy_name }}</span>
            </td>
            <td class='sl-text-align-left'>
              <select v-if='status.edit'
                      v-model='status.coverage_status'
                      class='sl-border-1px-medium-charcoal sl-h-28px sl-w-90per'
                      required>
                <option v-for='option in coverageStatusOptions' :key='option'>{{ option }}</option>
              </select>
              <span v-else>{{ status.coverage_status }}</span>
            </td>
            <td class='sl-text-align-left'>
              <select v-if='status.edit'
                      v-model='status.compliance_status'
                      class='sl-border-1px-medium-charcoal sl-h-28px sl-w-90per'
                      required>
                <option v-for='option in complianceStatusOptions' :key='option'>{{ option }}</option>
              </select>
              <span v-else>{{ status.compliance_status }}</span>
            </td>
            <td class='sl-text-align-left'>
              <input v-if='status.edit'
                      v-model='status.year'
                      type='number'
                      class='sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px sl-w-90per
                            sl-disable-number-input-up-down-arrows'
                      :min='minYear'
                      :max='maxYear'
                      required/>
              <span v-else>{{ status.year }}</span>
            </td>
            <td class='sl-text-align-left sl-overflow-wrap-anywhere'>
              <div v-if='status.edit' class='sl-flex-only'>
                <textarea v-model='status.notes'
                          @input="autoFitTextAreaOnInput"
                          class='sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px sl-w-90per'>
                </textarea>
                <div class='sl-w-45px sl-flex-justify-content-end'>
                  <deleteIcon @click='destroy(status)' class='figma-icon-delete links-center' transform ='scale(0.7)'/>
                </div>
              </div>
              <div v-else class='sl-flex-only'>
                <div class='sl-w-90per sl-text-wrap'>{{ status.notes || '&#8212'}}</div>
                <div class='sl-w-45px sl-flex-justify-content-end'>
                  <editIcon v-if='!status.edit' @click='edit(status)' class='figma-icon links-center' style='margin-top: 2px' transform ='scale(0.9)'/>
                  <deleteIcon @click='destroy(status)' class='figma-icon-delete links-center' transform ='scale(0.7)'/>
                </div>
              </div>
            </td>
          </tr>
          <!-- Temporary row for adding new compliance status -->
          <tr v-if='addStatus' class='sl-h-42px'>
            <td class='sl-text-align-left'>
              <select v-model="newStatus.policy_name"
                      class='sl-border-1px-medium-charcoal sl-h-28px sl-w-90per'
                      required>
                <option v-for='policy in activePolicies' :key='policy.id'>{{ policy }}</option>
              </select>
            </td>
            <td class='sl-text-align-left'>
              <select v-model='newStatus.coverage_status'
                      class='sl-border-1px-medium-charcoal sl-h-28px sl-w-90per'
                      required>
                <option v-for='option in coverageStatusOptions' :key='option'>{{ option }}</option>
              </select>
            </td>
            <td class='sl-text-align-left'>
              <select v-model='newStatus.compliance_status'
                      class='sl-border-1px-medium-charcoal sl-h-28px sl-w-90per'
                      required>
                <option v-for='option in complianceStatusOptions' :key='option'>{{ option }}</option>
              </select>
            </td>
            <td class='sl-text-align-left'>
              <input type='number'
                    v-model='newStatus.year'
                    class='sl-border-1px-medium-charcoal sl-h-28px sl-w-90per
                          sl-disable-number-input-up-down-arrows sl-pl-5px'
                    :min='minYear'
                    :max='maxYear'
                    required/>
            </td>
            <td class='sl-text-align-left'>
              <div class='sl-flex-only'>
                <textarea v-model='newStatus.notes'
                          @input="autoFitTextAreaOnInput"
                          class='sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px sl-w-90per'>
                </textarea>
                <div class='sl-w-45px'></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class='sl-font-size-16px sl-border-b-2px sl-flex-justify-align-center sl-border-t-2px-primary-blue' style='height: 75.33px'>
        There are no compliance statuses associated with this property.
      </div>
      <br>
    </form>
  </CollapsibleSection>
</template>
<script>
import axios from 'axios';
import CollapsibleSection from '../../../components/CollapsibleSection.vue';
import editIcon from '../../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';

export default {
  components: {
    CollapsibleSection,
    editIcon,
    deleteIcon,
  },
  data() {
    this.searchParams = new URLSearchParams(window.location.search);
    const projectId = this.searchParams.get('project_id');
    return {
      projectId,
      statuses: [],
      activePolicies: [],
      coverageStatusOptions: [],
      complianceStatusOptions: [],
      minYear: new Date().getFullYear() - 100,
      maxYear: new Date().getFullYear() + 100,
      addStatus: false,
      editStatus: false,
      editId: null,
      showSpinningWheel: false,
      alertMessage: null,
      errorMessage: null,
      disableButtons: false,
      newStatus: {
        policy_name: null,
        compliance_status: null,
        notes: null,
        year: null,
      },
    };
  },

  mounted() {
    this.fetchComplianceStatuses();
  },

  methods: {
    async fetchComplianceStatuses() {
      this.showSpinningWheel = true;
      await axios.get('/imt_compliance_statuses', {
        params: {
          project_id: this.projectId,
        },
      })
        .then((response) => {
          this.statuses = response.data.compliance_statuses;
          this.activePolicies = response.data.active_policies;
          this.coverageStatusOptions = response.data.coverage_status_options;
          this.complianceStatusOptions = response.data.compliance_status_options;
        })
        .catch(() => {
          this.alertMessage = null;
          this.errorMessage = 'Error fetching compliance statuses';
        })
        .finally(() => {
          setTimeout(() => {
            this.showSpinningWheel = false;
          }, 500);
        });
    },

    async create() {
      this.showSpinningWheel = true;
      this.disableButtons = true;
      await axios.post('/imt_compliance_statuses', {
        project_id: this.projectId,
        imt_compliance_status: this.newStatus,
      })
        .then((response) => {
          setTimeout(() => {
            this.newStatus.id = response.data.id;
            this.statuses.push(this.newStatus);
            this.resetNewStatus();
            this.addStatus = false;
            this.disableButtons = false;
            this.showSpinningWheel = false;
            this.alertMessage = 'Status Saved';
            this.errorMessage = null;
            setTimeout(() => {
              this.alertMessage = '';
            }, 2000);
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            this.disableButtons = false;
            this.showSpinningWheel = false;
            this.alertMessage = null;
            this.errorMessage = 'Error saving status';
          }, 500);
        });
    },

    async update() {
      this.showSpinningWheel = true;
      this.disableButtons = true;
      const status = this.statuses.find((el) => el.id === this.editId);
      await axios.put(`/imt_compliance_statuses/${this.editId}`, null, {
        params: {
          imt_compliance_status: status,
        },
      })
        .then(() => {
          setTimeout(() => {
            this.editStatus = false;
            status.edit = false;
            this.disableButtons = false;
            this.showSpinningWheel = false;
            this.alertMessage = 'Status Updated';
            this.errorMessage = null;
            setTimeout(() => {
              this.alertMessage = '';
            }, 2000);
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            this.disableButtons = false;
            this.showSpinningWheel = false;
            this.alertMessage = null;
            this.errorMessage = 'Error updating status';
          }, 500);
        });
    },

    async destroy(status) {
      if (!window.confirm('Are you sure you want to delete this compliance status?')) { return; }
      this.showSpinningWheel = true;
      this.disableButtons = true;
      if (status.edit) {
        this.editStatus = false;
      }
      await axios.delete(`/imt_compliance_statuses/${status.id}`)
        .then(() => {
          this.statuses = this.statuses.filter((el) => el.id !== status.id);
          this.disableButtons = false;
          this.showSpinningWheel = false;
          this.alertMessage = 'Status Deleted';
          this.errorMessage = null;
          setTimeout(() => {
            this.alertMessage = '';
          }, 2000);
        })
        .catch(() => {
          this.disableButtons = false;
          this.showSpinningWheel = false;
          this.alertMessage = null;
          this.errorMessage = 'Error deleting status';
        });
    },

    save() {
      if (this.editStatus) {
        this.update();
      } else {
        this.create();
      }
    },

    createNewStatus() {
      this.resetNewStatus();
      this.alertMessage = null;
      this.errorMessage = null;
      this.addStatus = true;
      this.editStatus = false;
      this.editId = null;
    },

    edit(status) {
      this.cancel();
      const currentEdit = this.statuses.find((el) => el.edit === true);
      if (currentEdit) {
        currentEdit.edit = false;
      }
      this.editId = status.id;
      this.statuses.find((el) => el.id === this.editId).edit = true;
      this.editStatus = true;
      setTimeout(() => {
        this.autoFitTextAreasOnEdit();
      }, 100);
    },

    // Cancel creation or editing of a compliance status
    cancel() {
      this.addStatus = false;
      this.editStatus = false;
      this.editId = null;
      this.alertMessage = null;
      this.errorMessage = null;
      const currentEdit = this.statuses.find((el) => el.edit === true);
      if (currentEdit) {
        currentEdit.edit = false;
      }
      this.resetNewStatus();
    },

    // Reset the new status object
    resetNewStatus() {
      this.newStatus = {
        policy_name: '',
        compliance_status: '',
        year: '',
        notes: '',
      };
    },

    // Automatically expand textareas to fit the content when inputting
    autoFitTextAreaOnInput(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },

    // Automatically expand textareas to fit the content when editing
    autoFitTextAreasOnEdit() {
      const textareas = document.querySelectorAll('textarea');
      textareas.forEach((textarea) => {
        const el = textarea;
        if (el.scrollHeight > 40) {
          el.style.height = `${el.scrollHeight}px`;
        }
      });
    },
  },
};
</script>
<style scoped>
textarea {
  resize: none;
  transition: height 0.4s ease-in-out;
}
</style>
