<template>
  <div class='sl-pb-30px'>
    <div class='sl-flex-justify-content-end sl-h-28px sl-mb-10px'>
      <div v-if='importInProgress' class='sl-spinning-wheel-20px'></div>
    </div>
    <div class='sl-flex-justify-content-start sl-flex-align-items-center sl-mb-10px'>
      <button @click='importReferences' class='sl-simple-outline-btn sl-w-100px sl-h-28px'>Import</button>
      <div class='sl-ml-20px sl-font-size-14px'>
        <div>
          Import default references.
          <span class='sl-bold-text'>This will overwrite any existing data on the table.</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.projectId = urlParams.get('project_id');
    this.projectScopeId = urlParams.get('project_scope_id');
  },

  data() {
    return {
      projectId: null,
      projectScopeId: null,
      importInProgress: false,
    };
  },

  methods: {
    async importReferences() {
      const text = 'Caution: This will overwrite existing table data. Are you sure you want to import default values?';
      if (!window.confirm(text)) { return; }
      this.importInProgress = true;
      await axios.post('/esa_report_tables/create_default_references_table', {
        project_id: this.projectId,
        project_scope_id: this.projectScopeId,
      })
        .catch(() => {
          this.errorMessage = 'Error importing references';
        })
        .finally(() => {
          this.importInProgress = false;
          this.$emit('importComplete');
        });
    },
  },
};
</script>
