<template>
  <div class='sl-mt-20px sl-mb-5px sl-flex-justify-content-space-btwn'>
    <div class='sl-w-33per'>
      <span class='sl-font-size-14px'>{{ pathwayName }}</span>
    </div>
    <div class='sl-flex-justify-content-end sl-w-33per' id='carbon_offset_type_selection'>
      <label :for="`${category}_annual_carbon_offset`" class='sl-custom-radio sl-mr-20px'>
        Annual
        <input @click="offsetType='annual'"
              :id="`${category}_annual_carbon_offset`"
              :name="`${category}_offset_type`"
              :checked="offsetType=='annual'"
              type='radio'>
        <span class='checkmark'></span>
      </label>
      <label :for="`${category}_total_carbon_offset`" class='sl-custom-radio sl-mr-20px'>
        Total
        <input @click="offsetType='total'"
              :id="`${category}_total_carbon_offset`"
              :name="`${category}_offset_type`"
              :checked="offsetType=='total'"
              type='radio'>
        <span class='checkmark'></span>
      </label>
    </div>
  </div>
  <div>
    <div v-if="offsetType=='total'" class='sl-flex-only'>
      <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
        <thead>
          <tr class='sl-table__row sl-table__row--esg'>
            <td class='sl-w-220px sl-flex-justify-content-end'></td>
          </tr>
        </thead>
        <tbody>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
            <td class='sl-w-220px sl-flex-justify-content-start sl-border-b-1px'>
              <span class='sl-ml-10px'>{{ costUnits }}</span>
            </td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Excess With Measures (tCO2e)</span>
            </td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Penalty With Measures</span>
            </td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Excess Without Measures (tCO2e)</span>
            </td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Penalty Without Measures</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class='sl-esg-overflow-container'>
        <table class='sl-esg-decarbonization-table sl-w-100per' id='sl-annual-carbon-offsets-table'>
          <thead>
            <tr class='sl-table__row sl-table__row--esg'>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
              <td v-if='localOrdinance || customPathway' class='sl-flex-column'>
                <span>{{ totalOffset.price_per_metric_ton }}</span>
                <span class='sl-bold-text'>(Average)</span>
              </td>
              <td v-else>
                <input @change='updateCarbonOffset(totalOffset)'
                      v-model='totalOffset.price_per_metric_ton'
                      placeholder='$0.00'
                      type='text'
                      class='sl-w-100per'/>
              </td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
              <td v-html='totalOffset.emission_offset'></td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
              <td v-html='totalOffset.cost'></td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
              <td v-html='totalOffset.emission_offset_without_measures'></td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
              <td v-html='totalOffset.cost_without_measures'></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="offsetType=='annual'" class='sl-flex-only'>
      <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
        <thead>
          <tr class='sl-table__row sl-table__row--esg'>
            <td class='sl-w-220px sl-flex-justify-content-end'>
              <span class='sl-w-100per sl-h-45px'></span>
            </td>
            <td class='sl-w-85px sl-flex-justify-content-end'>
              <span class='sl-w-100per sl-flex-justify-align-center sl-h-45px'>Total</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>{{ costUnits }}</span>
            </td>
            <td class='sl-w-85px sl-flex-column'>
              <span>{{ offsetTotals.price_per_metric_ton }}</span>
              <span class='sl-bold-text'>(Average)</span>
            </td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Excess With Measures (tCO2e)</span>
            </td>
            <td class='sl-w-85px'>{{ offsetTotals.emission_offset }}</td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Penalty With Measures</span>
            </td>
            <td class='sl-w-85px'>{{ offsetTotals.cost }}</td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Excess Without Measures (tCO2e)</span>
            </td>
            <td class='sl-w-85px'>{{ offsetTotals.emission_offset_without_measures }}</td>
          </tr>
          <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
            <td class='sl-w-220px sl-flex-justify-content-start'>
              <span class='sl-ml-10px'>Penalty Without Measures</span>
            </td>
            <td class='sl-w-85px'>{{ offsetTotals.cost_without_measures }}</td>
          </tr>
        </tbody>
      </table>
      <div class='sl-esg-overflow-container'>
        <table class='sl-esg-decarbonization-table' id='sl-annual-carbon-offsets-table'>
          <thead>
            <tr class='sl-table__row sl-table__row--esg'>
              <td v-for='year in years' class='sl-w-7per'>{{ year }}</td>
            </tr>
          </thead>
          <tbody>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
              <td v-for='(carbonOffset, index) in carbonOffsets'>
                <span v-if='localOrdinance || customPathway'>{{ carbonOffset.price_per_metric_ton }}</span>
                <input v-else @change='updateCarbonOffset(carbonOffset, index)'
                      v-model='carbonOffset.price_per_metric_ton'
                      placeholder='$0.00'
                      type='text'
                      class='sl-w-100per'/>
              </td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
              <td v-for='carbonOffset in carbonOffsets' class='sl-w-7per'>
                <span v-html='carbonOffset.emission_offset'></span>
              </td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
              <td v-for='carbonOffset in carbonOffsets' class='sl-w-7per'>
                <span v-html='carbonOffset.cost'></span>
              </td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
              <td v-for='carbonOffset in carbonOffsets' class='sl-w-7per'>
                <span v-html='carbonOffset.emission_offset_without_measures'></span>
              </td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
              <td v-for='carbonOffset in carbonOffsets' class='sl-w-7per'>
                <span v-html='carbonOffset.cost_without_measures'></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pathwayName: String,
    localOrdinance: Boolean,
    totalOffset: Object,
    carbonOffsets: Array,
    offsetTotals: Object,
    years: Array,
    category: String,
    costUnits: String,
    customPathway: Boolean,
  },

  data() {
    return {
      offsetType: 'annual',
    };
  },

  methods: {
    updateCarbonOffset(carbonOffset, index = null) {
      this.$emit('update-carbon-offset', carbonOffset, this.category, index);
    },
  },
};
</script>
