<template>
  <div class='collapsable-container'>
    <div :class="['bar', { 'bar-open': isOpen, 'bar-closed': (!isOpen && toggleHeaderColor) }]" @click="toggleContent">
      <p class='sl-font-size-14px'>{{ barText }}</p>
      <p class='sl-flex-align-items-center'>
        <span v-if='!isOpen' class='header-plus-icon'>+</span>
        <span v-else class='header-minus-icon'>-</span>
      </p>
    </div>
    <transition name='slide'>
      <div v-if="isOpen" class='content'>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    barText: {
      type: String,
      required: true,
    },
    toggleHeaderColor: {
      type: Boolean,
      default: true,
    },
    bottomBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: true,
      bottomBorderStyle: '2px solid var(--primary-blue)',
    };
  },
  mounted() {
    this.setBottomBorder();
  },
  methods: {
    toggleContent() {
      this.isOpen = !this.isOpen;
    },

    setBottomBorder() {
      if (!this.bottomBorder) {
        this.bottomBorderStyle = 'none';
      }
    },
  },
};
</script>

<style scoped>
.bar {
  color: white;
  cursor: pointer;
  text-align: center;
  user-select: none;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  background-color: var(--primary-blue);
  font-weight: bold;
}

.bar-open {
  background-color: var(--primary-blue);
}

.bar-closed {
  background-color: var(--medium-charcoal);
}

.header-plus-icon {
  font-size: 25px;
}

.header-minus-icon {
  font-size: 40px;
  margin-bottom: 3px;
}

.content  {
  padding-bottom: 10px;
  border-bottom: v-bind(bottomBorderStyle);
}

.collapsable-container {
  margin-top: 10px;
}

.slide-enter-active,
.slide-leave-active {
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  transition: all 1s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
  padding-bottom: 0;
  transition: all 0.5s ease-out;
}

.slide-leave-active::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: white;
  transform: translateX(100%);
  transition: transform 1.4s ease;
}

.slide-leave-to::after {
  transform: translateX(-100%);
}

.slide-enter-active::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: white;
  transition: transform 0.4s ease;
}

.slide-enter-to::after {
  transform: translateX(100%);
}
</style>
