<template>
  <CollapsibleSection :barText="'Other Policies'"
                      :toggleHeaderColor=false
                      :bottomBorder=false
                      class='sl-mb-20px'>
    <table v-if='inactivePolicies.length' class='sl-simple-table-1 sl-border-b-2px sl-font-size-14px'>
      <thead>
        <tr class='sl-h-42px sl-border-t-0'>
          <th class='sl-text-align-left sl-w-25per'>Policy Name</th>
          <th class='sl-text-align-left sl-w-15per'>Policy Type</th>
          <th class='sl-text-align-left sl-w-15per'>Sqft. Threshold</th>
          <th class='sl-text-align-left sl-w-15per'>Unit Threshold</th>
          <th class='sl-text-align-left sl-w-15per'>Status</th>
          <th class='sl-text-align-left sl-w-15per'>Explanation</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="policy in inactivePolicies" class='sl-h-60px sl-text-align-left'>
          <td><a :href="policy.policy_url">{{ policy.policy_name }}</a></td>
          <td>{{ policy.policy_type }}</td>
          <td>{{ policy.sqft_threshold }}</td>
          <td>{{ policy.mf_unit_threshold }}</td>
          <td>{{ policy.status }}</td>
          <td>{{ policy.explanation }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class='sl-font-size-16px sl-border-b-2px sl-h-95px sl-flex-justify-align-center'>
      There are no non active policies associated with this property.
    </div>
    <br>
  </CollapsibleSection>
</template>
<script>
import CollapsibleSection from '../../../components/CollapsibleSection.vue';

export default {
  components: {
    CollapsibleSection,
  },
  props: {
    inactivePolicies: {
      type: Array,
      required: true,
    },
  },
};
</script>
