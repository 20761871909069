<template>
  <div v-if="dataTransferLogsExist && modalVisible" id='sl-modal-container-type-1' style="background-color: rgba(0, 0, 0, 0.2)">
    <div id='sl-modal-content-type-1' class="sl-p-all-10px sl-flex-justify-content-center">
      <div class='sl-p-all-10px' style='width: 500px; height: 400px; background: rgb(255, 255, 255); margin-top: 100px; overflow-y: auto;'>
        <div class="sl-flex-justify-content-end">
          <CloseIcon @click="closeModal" style='cursor: pointer;'/>
        </div>

        <table class="sl-simple-table-1">
          <tr>
            <th>ID</th>
            <th>Initiated At</th>
            <th>Initiated By</th>
            <th>Transferred From</th>
            <th>Status</th>
          </tr>
          <tr v-for="data_transfer_log in data_transfer_logs">
            <td>{{ data_transfer_log.id }}</td>
            <td>{{ data_transfer_log.created_at }}</td>
            <td>{{ data_transfer_log.username }}</td>
            <td>{{ data_transfer_log.source_project_name }}</td>
            <td>{{ data_transfer_log.status }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <!-- button to show pop-up -->
  <div v-if="dataTransferLogsExist">
    <button class='sl-simple-outline-btn' @click="showModal">View data transfer log</button>
  </div>
</template>

<script>
import axios from 'axios';
import CloseIcon from '../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    CloseIcon,
  },

  data() {
    return {
      dataTransferLogsExist: false,
      modalVisible: false,
      data_transfer_logs: [],
    };
  },

  mounted() {
    const parentDiv = document.getElementById('sl_vue_data_transfer_log_pop_up');
    const project_id = parentDiv.getAttribute('project_id');
    const report_name = parentDiv.getAttribute('report_name');

    axios.get('/data_transfer_logs/return_data_transfer_logs', {
      params: {
        project_id,
        report_name,
      },
    })
      .then((response) => {
        this.data_transfer_logs = response.data.data_transfer_logs;

        if (this.data_transfer_logs.length >= 1) {
          this.dataTransferLogsExist = true;
        }
      })
      .catch(() => {
        // silence error for now
      });
  },

  methods: {
    closeModal() {
      this.modalVisible = false;
    },

    showModal() {
      this.modalVisible = true;
    },
  },
};
</script>
