<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 600px; height: 450px;'>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-w-750px sl-font-size-16px sl-mt-10px sl-mb-5px'>New Pathway</div>
        <div class='sl-w-100px sl-flex-justify-content-end'>
          <CloseIcon class='figma-icon' @click="$emit('closeModal')" id='sl-history-log-close'/>
        </div>
      </div>
      <div class='sl-border-t-2px-primary-blue'></div>
      <br>
      <br>
      <form @submit.prevent="createPathway"
            class='sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <div>
          <div class='sl-font-size-14px sl-mb-5px'>Select a Pathway Type:</div>
          <select v-model='pathwayType' @change='updateCostUnits' class='sl-border-1px-medium-charcoal sl-w-315px sl-h-32px'>
            <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
            <option>Carbon Emissions</option>
            <option>Emission Factor</option>
            <option>Energy Use Intensity</option>
          </select>
        </div>

        <div>
          <div class='sl-font-size-14px sl-mt-10px sl-mb-5px'></div>
          <select v-model='customType' class='sl-border-1px-medium-charcoal sl-w-315px sl-h-32px'>
            <option>Custom Pathway</option>
            <option>Percent Reduction</option>
          </select>
        </div>

        <div v-if="customType==='Custom Pathway'" class='sl-h-200px'>
          <div class='sl-font-size-14px sl-mt-20px sl-mb-5px'>Name:</div>
          <select v-model='pathwayName'
                  class='sl-border-1px-medium-charcoal sl-w-315px sl-h-32px'>
            <option value='' style='color: #9F9EA4;'>Custom Name</option>
            <option v-for='name in customPathwayNames' :value='name'>{{ name }}</option>
          </select>
          <div v-if= "['Carbon Emissions', 'Energy Use Intensity'].includes(pathwayType)">
            <div class='sl-font-size-14px sl-mt-20px sl-mb-5px'>Fee Schedule:</div>
            <select v-model='costUnits' class='sl-border-1px-medium-charcoal sl-w-315px sl-h-32px'>
              <option v-if="pathwayType === 'Carbon Emissions'">$/tCO2e</option>
              <option v-if="pathwayType === 'Energy Use Intensity'">$/kBtu</option>
              <option>$/year</option>
            </select>
          </div>
        </div>

        <div v-if="customType==='Percent Reduction'" class='sl-h-200px'>
          <div class='sl-font-size-14px sl-mt-20px sl-mb-5px'>&nbsp</div>
          <table class='sl-w-315px sl-border-t-1px sl-border-b-1px' style='border-collapse: collapse;'>
            <tbody>
              <tr v-for='index in indexArray' class='sl-table__row--striped-odd sl-h-42px'>
                <td><!-- Placeholder--></td>
                <td class='sl-w-75px sl-text-align-center sl-w-270px'>
                  <input v-model='percentages[index]'
                         class='sl-esg-custom-goal-pathway sl-disable-number-input-up-down-arrows'
                         type='number'
                         :min=1
                         :max=100>
                  <span class='sl-font-size-14px' style='color: #333E47'>&nbsp % reduction by &nbsp</span>
                  <input v-model='goalYears[index]'
                         class='sl-esg-custom-goal-pathway sl-disable-number-input-up-down-arrows'
                         type='number'
                         :min='years[0]'
                         :max='years[-1]'>
                </td>
                <td class='sl-w-30px sl-h-42px sl-flex-justify-align-center'>
                  <plusIcon v-if='index == 0 && indexArray.length < 4' @click='addNewRow'
                            class='figma-icon-blue links-center'/>
                  <deleteIcon v-if='index > 0' @click='removePercentage(index)'
                              class='figma-icon-delete links-center'/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class='sl-flex-justify-content-center sl-mt-50px'>
          <button class='sl-simple-outline-btn sl-w-100px'>Create</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>

import CloseIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import plusIcon from '../../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_16.svg';
import deleteIcon from '../../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_16.svg';

export default {
  props: {
    years: {
      type: Array,
      required: true,
    },
    customPathwayNames: {
      type: Array,
      required: true,
    },
  },
  components: {
    CloseIcon,
    plusIcon,
    deleteIcon,
  },

  data() {
    return {
      percentages: [],
      goalYears: [],
      indexArray: [0],
      showPercentageGoal: false,
      pathwayType: 'Carbon Emissions',
      customType: 'Custom Pathway',
      pathwayName: '',
      costUnits: '$/tCO2e',
    };
  },

  methods: {
    addNewRow() {
      if (this.indexArray.length > 3) {
        alert('You can only add up to 4 years in a single percentage goal pathway.');
        return;
      }
      this.indexArray.push(this.indexArray.length);
    },

    percentageGoalAvailable() {
      return ['Carbon Emissions', 'Energy Use Intensity'].includes(this.pathwayType);
    },

    removePercentage(index) {
      this.percentages.splice(index, 1);
      this.goalYears.splice(index, 1);
      this.indexArray.pop();
    },

    createPathway() {
      if (this.validateForm()) {
        this.$emit('create', this.pathwayType, this.customType, this.pathwayName, this.costUnits, this.percentages, this.goalYears);
      }
    },

    validateForm() {
      if (this.customType === 'Percent Reduction') {
        if (this.percentages.length === 0) {
          alert('Please add at least one percentage goal.');
          return false;
        }
        if (this.percentages.length !== this.goalYears.length) {
          alert('Please fill in all of the percentage goals.');
          return false;
        }
        if (this.invalidReductionValues()) {
          alert('Please enter progressive reduction values.');
          return false;
        }
        if (this.invalidGoalYears()) {
          alert('Please enter progressive year values.');
          return false;
        }
      }
      return true;
    },

    // Make sure the percentage values are in increasing order
    invalidReductionValues() {
      let invalid = false;
      this.percentages.forEach((value, index) => {
        if (index > 0 && value <= this.percentages[index - 1]) {
          invalid = true;
        }
      });
      return invalid;
    },

    // Make sure the goal year values are in increasing order
    invalidGoalYears() {
      let invalid = false;
      this.goalYears.forEach((value, index) => {
        if (index > 0 && value <= this.goalYears[index - 1]) {
          invalid = true;
        }
      });
      return invalid;
    },

    // Update the fee schedule units based on the pathway type whenever the pathway type changes
    updateCostUnits() {
      if (this.pathwayType === 'Carbon Emissions' && this.costUnits !== '$/year') {
        this.costUnits = '$/tCO2e';
      } else if (this.pathwayType === 'Energy Use Intensity' && this.costUnits !== '$/year') {
        this.costUnits = '$/kBtu';
      } else if (this.pathwayType === 'Emission Factor') {
        this.costUnits = null;
      }
    },
  },

};
</script>
