<template>
  <div v-if="userIsDbAdmin">
    <div>DB Administration Functions:</div>
    <br>
    <br>

    <div class="sl-p-all-10px" style="border: 1px solid blue;">
      <h1>Backfill Project Scope ID Data</h1>

      <div class="sl-mtb-10px">
        <label>Project Id:</label>
        <input v-model="backfill_project_id" class="sl-w-250px" placeholder="Enter project Id">
      </div>

      <div class="sl-mtb-10px">
        <label>Project Start Date:</label>
        <input
          type="date"
          v-model="start_date"
          class="sl-w-250px"
          :required="true"
        >
      </div>

      <div class="sl-mtb-10px">
        <label>Project End Date:</label>
        <input
          type="date"
          v-model="end_date"
          class="sl-w-250px"
          :required="true"
        >
      </div>

      <div class="sl-mtb-10px">
        <button type="button" class="sl-simple-outline-btn" @click="backfill_project_scope_id_data">Start Backfill Job</button>
      </div>
    </div>
    <br>

    <div class="sl-p-all-10px" style="border: 1px solid blue;">
      <h1>Quick Create Project</h1>
      <br>
      <h1>Quickly create a project with a project name, project scope, and client.</h1>
      <br>
      
      <div class="sl-mtb-10px">
        <label>Project Name:</label>
        <input :required="true" v-model="project_name" class="sl-w-250px" placeholder="Enter project name">
      </div>

      <div class="sl-mtb-10px">
        <label>Project Scope:</label>
        <select v-model="selected_scope" class="sl-w-250px">
          <option value="">Select a scope</option>
          <option v-for="scope in available_scopes" 
                  :key="scope.id" 
                  :value="scope.id">
            {{ scope.name }}
          </option>
        </select>
      </div>

      <div class="sl-mtb-10px">
        <label>Client Search:</label>
        <input
          v-model="clientSearch"
          class="sl-w-250px"
          placeholder="Search for a client..."
          @input="searchClients"
        >
      </div>
      
      <div class="sl-mtb-10px" v-if="filteredClients.length">
        <label>Client Name:</label>
        <select 
          v-model="selected_client" 
          class="sl-w-250px"
        >
          <option value="">Select a client</option>
          <option 
            v-for="client in filteredClients" 
            :key="client.id"
            :value="client.id"
          >
            {{ client.name }}
          </option>
        </select>
      </div>

      <div class="sl-mtb-10px">
        <button type="button" 
                class="sl-simple-outline-btn" 
                @click="createProject"
                :disabled="!project_name || !selected_client">
          Create Project
        </button>
      </div>
    </div>
    <br>

    <div class="sl-p-all-10px" style="border: 1px solid blue;">
      <h1>BackgroundJob Table Record Reset</h1>
      <br>
      <h1>In rare cases, a BackgroundJob record will get stuck in "processing" status. If someone reports that it has been stuck for a while, we may need to reset the record.</h1>
      <br>
      <h1>The BackgroundJob table is currently used on the photolog creation, photo transfer, and box photo uploads.</h1>
      <br>
      <h1>With DBeaver, search the database for this:
         <br>
         <br>
         SELECT * FROM background_jobs bj WHERE status = 'processing'
         <br>
         <br>
         Find the correct record that has been stuck, 
        then enter the record ID below (Not project id). The record will be set to "failed" status.
      </h1>
      <input :required="true" v-model="background_job_id" class="sl-w-250px">

      <div class="sl-mtb-10px">
        <button type="button" class="sl-simple-outline-btn" @click="background_job_reset" :disabled="background_job_id === ''">Reset BackgroundJob Record</button>
      </div>
    </div>

    <br>
    <br>

    <div class="sl-p-all-10px" style="border: 1px solid blue;">
      <h1>locationpic public link creation</h1>
      <br>
      <h1>For backfilling old projects or renewing the links for current projects. The jobs will be scheduled to run on the current day at Midnight.</h1>
      <br>
      <h1>Check Sidekiq to see the scheduled jobs</h1>
      <br>
      <h1>Enter sitelynx unique project ID (Not Plink Project Number) to schedule locationpic public link creation.</h1>
      <input :required="true" v-model="locationpic_project_id" class="sl-w-250px">

      <div class="sl-mtb-10px">
        <button type="button" class="sl-simple-outline-btn" @click="locationpic_link_creation" :disabled="locationpic_project_id === ''">Schedule Locationpic Public Link Creation</button>
      </div>
    </div>

    <br>
    <br>

    <div class="sl-p-all-10px" style="border: 1px solid red;">
      <h1>Permanent Data Deletion</h1>
      <br>
      <h1>Data for all scopes and report writers in the project will be deleted. The jobs will be scheduled to run the next day at Midnight.</h1>
      <br>
      <h1>Check Sidekiq to see the scheduled jobs</h1>
      <br>
      <h1>Enter sitelynx unique project ID (Not Plink Project Number) to schedule deletion</h1>
      <input :required="true" v-model="data_deletion_project_id" class="sl-w-250px">

      <div class="sl-mtb-10px">
        <button type="button" class="sl-simple-outline-btn" @click="schedule_data_deletion('photos')" :disabled="data_deletion_project_id === ''">Schedule Permanent Photo Deletion</button>
      </div>

      <div class="sl-mtb-10px">
        <button type="button" class="sl-simple-outline-btn" @click="schedule_data_deletion('documents')" :disabled="data_deletion_project_id === ''">Schedule Permanent Document Deletion</button>
      </div>

      <div class="sl-mtb-10px">
        <button type="button" class="sl-simple-outline-btn" @click="schedule_data_deletion('bluelynx_data')" :disabled="data_deletion_project_id === ''">Schedule Permanent BlueLynx Data Deletion</button>
      </div>

      <div class="sl-mtb-10px">
        <button type="button" class="sl-simple-outline-btn" @click="schedule_data_deletion('all')" :disabled="data_deletion_project_id === ''">Schedule Permanent All Project Data Deletion (includes all of above)</button>
      </div>
    </div>

    <br>
    <br>
    <strong>Application Settings (EDIT WITH CARE!)</strong>
    <br>
    <a href='/settings'><em class=' inline '>Edit Application Settings</em></a>
    <br>
    <br>
    <strong>Migrate Ewem Install Date</strong>
    <br>
    <a href='/admin/migrate_ewem_install_date'><em class=' inline '>Migrate Ewem Install Date</em></a>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

    <div>Currently in Production? {{ railsEnvProduction }}</div>
    <div v-if="railsEnvProduction === false" class="sl-p-all-10px" style="border: 1px solid green;">
      <p class="sl-bold-text" style="color: red;">This area is only enabled in non-production environments</p>

      <strong>Production Lookup Syncing</strong>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=pca_lookups' class=' button inline '><em class=' inline '>Sync PCA Lookups From Production</em></a>
      <br>
      <br>
      <strong>Production Field Syncing</strong>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=pca_fields'><em class=' inline '>Sync PCA Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=esa_fields' class=' button inline '><em class=' inline '>Sync ESA Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=seismic_fields' class=' button inline '><em class=' inline '>Sync Seismic Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=zoning_fields' class=' button inline '><em class=' inline '>Sync Zoning Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=alta_fields' class=' button inline '><em class=' inline '>Sync Alta Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=esg_fields' class=' button inline '><em class=' inline '>Sync ESG Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=rmp_fields' class=' button inline '><em class=' inline '>Sync RMP Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=cpm_fields' class=' button inline '><em class=' inline '>Sync CPM Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=client_fields' class=' button inline '><em class=' inline '>Sync Client Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=val_fields' class=' button inline '><em class=' inline '>Sync Valuation Fields From Production</em></a>
      <br>
      <a href='/admin/sync_pca_fields_from_production?field_type=juris_fields' class=' button inline '><em class=' inline '>Sync Jurisdiction Select Update Fields From Production</em></a>
      <br>
      <br>
      <strong>Report Names Syncing</strong>
      <br>
      <a href='/admin/sync_report_names_from_production' class=' button inline '><em class=' inline '>Sync Report Names From Production</em></a>
      <br>
      <br>
      <strong>Template Syncing</strong>
      <br>
      <a href='/admin/sync_templates_from_production'><em class=' inline '>Sync Templates From Production</em></a>
      <br>
      <br>
      <strong>Jurisdictions Syncing</strong>
      <br>
      <a href='/admin/sync_jurisdictions_from_production'><em class=' inline '>Sync Jurisdictions From Production</em></a>
      <br>
      <br>
      <strong>Jurisdiction Contacts Syncing</strong>
      <br>
      <a href='/admin/sync_jurisdiction_contacts_from_production'><em class=' inline '>Sync Jurisdiction Contacts From Production</em></a>
      <br>
      <br>
      <strong>Energy Star Portfolio Manager Syncing</strong>
      <br>
      <a href='/espm_properties/sync_all_properties'><em class=' inline '>Sync All Espm Properties on SiteLynx</em></a>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      railsEnvProduction: null,
      userIsDbAdmin: false,
      data_deletion_project_id: '',
      locationpic_project_id: '',
      background_job_id: '',
      project_name: '',
      selected_scope: '',
      selected_client: '',
      available_scopes: [],
      clientSearch: '',
      filteredClients: [],
      searchTimeout: null,
      start_date: '',
      end_date: '',
      backfill_project_id: null,
    };
  },

  async created() {
    await axios.get('/admin/return_dbadmin_variables')
      .then((response) => {
        this.railsEnvProduction = response.data.rails_env_production;
        this.userIsDbAdmin = response.data.user_is_db_admin;
        this.available_scopes = response.data.available_scopes || [];
      }).catch((error) => {
        console.log(error);
      });
  },

  methods: {
    backfill_project_scope_id_data() {
      let currentObj = this;

      axios.post('/admin/backfill_project_scope_id_data', {
        start_date: currentObj.start_date,
        end_date: currentObj.end_date,
        backfill_project_id: currentObj.backfill_project_id,
      })
      .then(function (response) {
        alert(response.data.result);
      })
      .catch(function (error) {
        alert(error);
      })
    },

    background_job_reset() {
      let currentObj = this;

      axios.post('/admin/background_job_reset', {
        background_job_id: currentObj.background_job_id,
      })
      .then(function (response) {
        alert(response.data.result);
      })
      .catch(function (error) {
        alert(error);
      })
    },

    locationpic_link_creation() {
      let currentObj = this;

      axios.post('/admin/schedule_locationpic_link_creation', {
        project_id: currentObj.locationpic_project_id,
      })
      .then(function (response) {
        alert(response.data.result);
      })
      .catch(function (error) {
        alert(error);
      })
    },

    schedule_data_deletion(data_type) {
      let currentObj = this;

      axios.post('/admin/schedule_data_deletion', {
        project_id: currentObj.data_deletion_project_id,
        data_type: data_type,
      })
      .then(function (response) {
        alert(response.data.result);
      })
      .catch(function (error) {
        alert(error);
      })
    },

    createProject() {
      let currentObj = this;

      axios.post('/admin/dbadmin_quick_create_project', {
        project_name: currentObj.project_name,
        workscope_id: currentObj.selected_scope,
        client_id: currentObj.selected_client
      })
      .then(function (response) {
        alert(response.data.result);
      })
      .catch(function (error) {
        alert(error);
      });
    },

    searchClients() {
      // Debounce the search to prevent too many API calls
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        if (this.clientSearch.length < 2) {
          this.filteredClients = [];
          return;
        }

        try {
          const response = await axios.get('/admin/search_clients', {
            params: { query: this.clientSearch }
          });
          this.filteredClients = response.data;
        } catch (error) {
          console.error('Error searching clients:', error);
          this.filteredClients = [];
        }
      }, 300);
    },
  },
};
</script>

<style>

</style>
