<template>
  <div class='sl-flex-justify-content-center sl-pb-10px'>
    <div class='sl-w-500px'>
      <div class='sl-flex-justify-content-and-align-items-center sl-mb-20px'>
        <div class='sl-box-shadow-1 sl-p-all-10px'>
          <div class='sl-mb-10px'>
            <strong>Upload JSON Data:</strong>
          </div>
          <form @submit.prevent='submit' class='sl-flex-column'>
            <input type='file' name='file' accept='.json'
                   class='sl-mb-10px file-input'
                   id='upload_datafile'
                   ref='upload_datafile'
                   @change='setJsonFile'/>
            <button class='sl-simple-outline-btn sl-w-100px'
                    :class="{'sl-simple-outline-btn-disabled':!file}">
                    Upload
            </button>
          </form>
        </div>
      </div>
      <ol>
        <li class='report-table-instructions'>
          Outline the subject property boundaries with a yellow line; ensure the boundaries are
          correct on each aerial and adjust accordingly.
        </li>
        <li class='report-table-instructions'>
          Discuss observations of the subject property first then discuss adjoining properties as
          a separate paragraph.
        </li>
        <li class='report-table-instructions'>
          For the subject property: include the number of buildings or other features and locations
          (northern portion, central portion, throughout the subject property, etc.).
        </li>
        <li class='report-table-instructions'>
          For the subject property: describe likely or apparent use and features if readily apparent
          (i.e. single-family residential or apparent commercial), but refrain from describing actual
          uses that cannot be determined through aerial photo review, such as drycleaner, gas station,
          auto repair, etc.
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.project_id = urlParams.get('project_id');
    this.project_scope_id = urlParams.get('project_scope_id');
  },

  data() {
    return {
      project_id: null,
      project_scope_id: null,
      file: null,
    };
  },

  methods: {
    async submit() {
      const text = 'Caution: This will overwrite existing table data. Are you sure you want to import this file?';
      if (!window.confirm(text)) { return; }
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('project_id', this.project_id);
      formData.append('project_scope_id', this.project_scope_id);
      formData.append('the_group', 'aerial photo');
      this.file = null;
      await axios.post('/esa_report_tables/import_json_data', formData)
        .then(() => {
          this.$emit('importComplete');
        });
    },

    setJsonFile() {
      const file = this.$refs.upload_datafile.files[0];
      if (file && file.type === 'application/json') {
        this.file = file;
      }
    },
  },
};
</script>
