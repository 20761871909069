<template>
  <div class='sl-mt-10px sl-mb-40px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style'>Carbon Performance Projects Import</h1>
  </div>
  <div class='sl-flex-justify-content-center sl-h-15px'>
    <span v-if='alertMessage' class='sl-ml-20px sl-min-w-85px sl-partner-blue-text sl-bold-text'>{{ alertMessage }}</span>
    <span v-else class='sl-ml-20px sl-min-w-85px' style='color: red;'>{{ errorMessage }}</span>
  </div>
  <div class='sl-font-size-14px sl-text-align-center'>
    <span class='sl-mr-5px' style="font-size: 20px; color: #F04C25">*</span>
    <span>A maximum of 100 projects can be imported at once.</span>
  </div>
  <ExcelDropzone
    @upload-complete='uploadComplete()'
    @upload-error='uploadError()'
    :formUrl="'/esg_excel_imports/import_carbon_proforma_projects'"
    :title="'Upload your Carbon Performance Projects'"
    :maxFiles="100"
    :parallelUploads="100"/>

</template>
<script>
import ExcelDropzone from '../../../components/ExcelDropzone.vue';

export default {
  components: {
    ExcelDropzone,
  },

  data() {
    return {
      alertMessage: '',
      errorMessage: '',
    };
  },

  methods: {
    uploadComplete() {
      this.alertMessage = 'Upload complete';
      setTimeout(() => {
        this.alertMessage = '';
      }, 3000);
    },

    uploadError() {
      this.errorMessage = 'Upload error';
    },
  },
};
</script>
