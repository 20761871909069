<template>
  <CollapsibleSection :barText="'Property Information'"
                      :toggleHeaderColor=false
                     :bottomBorder=false
                     class='sl-mb-20px'>
    <table class='sl-w-100per sl-font-size-14px sl-simple-table-1 sl-border-b-2px'>
      <tbody>
        <tr class='sl-h-45px sl-text-align-left'>
          <td class='sl-w-275px' style='color: #696E76'>Property Type</td>
          <td>{{ propertyData.property_type }}</td>
        </tr>
        <tr class='sl-h-45px sl-text-align-left'>
          <td style='color: #696E76'>Building Area</td>
          <td>{{ propertyData.building_area }}</td>
        </tr>
        <tr class='sl-h-45px sl-text-align-left'>
          <td style='color: #696E76'>Number Of Units</td>
          <td>{{ propertyData.number_of_units }}</td>
        </tr>
      </tbody>
    </table>
    <br>
  </CollapsibleSection>
</template>
<script>
import CollapsibleSection from '../../../components/CollapsibleSection.vue';

export default {
  components: {
    CollapsibleSection,
  },
  props: {
    propertyData: {
      type: Object,
      required: true,
    },
  },
};
</script>
