<template>
  <SpinningModal v-if='showSpinningModal' :modalLoadingText="'Fetching Policies...'"/>
  <div v-if='dataReady'>
    <div class='sl-flex-justify-content-space-btwn'>
      <h1 class='nd_photos_h1_style sl-mtb-10px sl-w-33per'>Energy Compliance</h1>
      <div class='sl-font-size-14px sl-bold-text sl-w-33per sl-flex-justify-align-center'
        :class="{ 'sl-partner-blue-text':alertMessage, 'sl-red-text':errorMessage }">
        {{ alertMessage || errorMessage }}
      </div>
      <div class='sl-flex-justify-content-end sl-flex-align-items-center sl-w-33per'>
        <span v-if='showSpinningWheel' class='sl-spinning-wheel-20px sl-mr-10px'></span>
        <button @click='approvePolicies' class='sl-simple-outline-btn sl-mr-10px sl-w-100px'>
          {{ approved ? 'Unapprove' : 'Approve' }}
        </button>
        <div v-if='userIsStaff' class='sl-tooltip sl-flex-align-items-center'>
          <button @click='updatePolicies' class='sl-simple-outline-btn sl-w-100px'>Update</button>
          <span class='sl-tooltiptext'>Staff Only</span>
        </div>
      </div>
    </div>
    <PropertyInformation :propertyData="propertyData"/>
    <ActivePolicies :activePolicies="activePolicies"/>
    <ComplianceStatus/>
    <InactivePolicies :inactivePolicies="inactivePolicies" />
  </div>
</template>
<script>
import axios from 'axios';
import PropertyInformation from './PropertyInformation.vue';
import ActivePolicies from './ActivePolicies.vue';
import InactivePolicies from './InactivePolicies.vue';
import ComplianceStatus from './ComplianceStatus.vue';
import SpinningModal from '../../../components/SpinningModal.vue';

export default {
  components: {
    PropertyInformation,
    ActivePolicies,
    InactivePolicies,
    ComplianceStatus,
    SpinningModal,
  },
  data() {
    return {
      projectScopeId: null,
      propertyData: {},
      activePolicies: [],
      inactivePolicies: [],
      userIsStaff: false,
      showSpinningWheel: false,
      alertMessage: null,
      errorMessage: null,
      approved: false,
      dataReady: false,
      showSpinningModal: false,
    };
  },

  created() {
    this.setProjectScopeId();
    this.fetchPolicies();
  },
  methods: {
    setProjectScopeId() {
      const searchParams = new URLSearchParams(window.location.search);
      this.projectScopeId = searchParams.get('project_scope_id');
    },

    async fetchPolicies() {
      this.showSpinningModal = true;
      axios.get('/imt_project_policies/return_policies_json', {
        params: {
          project_scope_id: this.projectScopeId,
        },
      })
        .then((response) => {
          this.propertyData = response.data.property_data;
          this.activePolicies = response.data.active_policies;
          this.inactivePolicies = response.data.inactive_policies;
          this.userIsStaff = response.data.user_is_staff;
          this.approved = response.data.approved;
        })
        .catch(() => {
          this.errorMessage = 'Error fetching policies';
        })
        .finally(() => {
          setTimeout(() => {
            this.dataReady = true;
            this.showSpinningModal = false;
          }, 500);
        });
    },

    async approvePolicies() {
      if (!this.confirmApproval()) { return; }
      this.showSpinningWheel = true;
      axios.post('/project_scopes/approve_project_scope_data', {
        project_scope_id: this.projectScopeId,
        approving_data: this.approved ? 'false' : 'true',
      })
        .then(() => {
          this.approved = !this.approved;
          this.alertMessage = this.approved ? 'Policies Approved' : 'Policies Unapproved';
          setTimeout(() => {
            this.alertMessage = null;
          }, 3000);
        })
        .catch(() => {
          this.errorMessage = 'Error approving policies';
        })
        .finally(() => {
          setTimeout(() => {
            this.showSpinningWheel = false;
          }, 500);
        });
    },

    async updatePolicies() {
      this.showSpinningWheel = true;
      axios.post('/imt_project_policies/add_policies_to_project', {
        project_scope_id: this.projectScopeId,
      })
        .then((response) => {
          this.activePolicies = response.data.active_policies;
          this.inactivePolicies = response.data.inactive_policies;
          this.errorMessage = null;
          this.alertMessage = 'Policies Updated';
          setTimeout(() => {
            this.alertMessage = null;
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = null;
          this.errorMessage = 'Error updating policies';
        })
        .finally(() => {
          setTimeout(() => {
            this.showSpinningWheel = false;
          }, 500);
        });
    },

    confirmApproval() {
      let confirmMessage;
      if (this.approved) {
        confirmMessage = 'This will remove approved policies from analytics. Are you sure you want to continue?';
      } else {
        confirmMessage = 'This will add approved policies to analytics. Are you sure you want to continue?';
      }
      if (window.confirm(confirmMessage)) {
        return true;
      }
      return false;
    },
  },
};
</script>
